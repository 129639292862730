export enum ROCK_TYPES {
  LAPIZ_LAZULI = 'Lapis Lazuli',
  JADE = 'Jade',
  OBSIDIAN = 'Obsidian',
  MARBLE = 'Marble',
  RUBY = 'Ruby',
  ONYX = 'Onyx',
  EMERALD = 'Emerald',
  GYPSUM = 'Gypsum',
  TOPAZ = 'Topaz'
}
