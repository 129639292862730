
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',

  data () {
    return {
      drawer: false
    }
  },
  watch: {
    '$route.name': function () {
      this.drawer = false
    }
  },
  computed: {
    navigationItems () {
      return [
        {
          title: 'Choose Rocks',
          path: this.$router.resolve({ name: 'RockPicker' }),
          disabled: this.$store.state.rockLists.tumbling.length >= 3
        },
        {
          title: 'Tumble Rocks',
          path: this.$router.resolve({ name: 'Tumbler' }),
          disabled: false
        },
        {
          title: 'Trophy Case',
          path: this.$router.resolve({ name: 'Trophy' }),
          disabled: false
        },
        {
          title: 'Team',
          path: this.$router.resolve({ name: 'Credits' }),
          disabled: false
        },
        {
          title: 'Save/Load',
          path: this.$router.resolve({ name: 'SaveAndLoad' }),
          disabled: false
        }
      ]
    }
  }
})
